import { useQuery } from '@tanstack/react-query';
import dynamic from 'next/dynamic';
import NextLink from 'next/link';
import PropTypes from 'prop-types';

import Button from 'shopper/components/Button';
import Icon from 'shopper/components/Icon';

import { isSponsoredVisible } from 'lib/date';
import { sendEvent } from 'lib/gtag';
import placeholder from 'lib/placeholder';
import { landingQueryKeys } from 'lib/queryKeys';

import { getLandingsList } from 'services/landing';

import CategoriesDropdownButton from './CategoriesDropdownButton';
import StoresDropdownButton from './StoresDropdownButton';

import LINKS from 'constants/links';

const FeaturedLandingLink = dynamic(() => import('./FeaturedLandingLink'), {
  ssr: true,
});

const MAIN_BUTTONS_LIST = [
  {
    href: LINKS.BLOG,
    text: placeholder('actions.seeBlog'),
    gaEventAction: 'topbar_blog_tab',
    testSelectorAttr: 'blog-tab',
    icon: <Icon name="article" />,
  },
  {
    href: LINKS.COUPONS,
    text: placeholder('actions.seeCoupons'),
    gaEventAction: 'topbar_coupon_tab',
    testSelectorAttr: 'coupons-tab',
    icon: <Icon name="discount-filled" />,
  },
  {
    href: LINKS.FORUM,
    text: placeholder('actions.seeForum'),
    gaEventAction: 'topbar_forum_tab',
    testSelectorAttr: 'forum-tab',
    icon: <Icon name="messages" />,
  },
  {
    href: LINKS.AMAZON_STORE,
    text: placeholder('actions.seeAmazonSponsored'),
    gaEventAction: 'topbar_amazon_tab',
    testSelectorAttr: 'amazon-tab',
    icon: <Icon name="amazon" />,
  },
];

const getButtonsList = () => {
  if (isSponsoredVisible()) {
    return MAIN_BUTTONS_LIST.filter(
      (button) => button.testSelectorAttr !== 'amazon-tab'
    );
  }
  return MAIN_BUTTONS_LIST;
};

const TopbarBottomDefaultNav = ({ gaEventCategory }) => {
  const { data: featuredLanding } = useQuery({
    queryKey: landingQueryKeys.featured(),
    queryFn: ({ signal }) => getLandingsList({ featured: true }, { signal }),
    enabled: false,
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <CategoriesDropdownButton gaEventCategory={gaEventCategory} />
      <StoresDropdownButton gaEventCategory={gaEventCategory} />
      {getButtonsList().map(
        ({ gaEventAction, href, text, testSelectorAttr, icon }) => {
          return (
            <NextLink key={href} href={href} prefetch={false} passHref>
              <Button
                as="a"
                className="mr-4"
                data-test-selector={testSelectorAttr}
                iconLeft={icon}
                size="size4"
                type="neutral-ghost"
                onClick={() => {
                  sendEvent({
                    action: gaEventAction,
                    category: gaEventCategory,
                  });
                }}
              >
                {text}
              </Button>
            </NextLink>
          );
        }
      )}
      {featuredLanding && (
        <FeaturedLandingLink
          gaEventCategory={gaEventCategory}
          landingName={featuredLanding.landingName}
          landingSlug={featuredLanding.landingSlug}
          landingType={featuredLanding.landingType}
        />
      )}
    </>
  );
};

TopbarBottomDefaultNav.propTypes = {
  gaEventCategory: PropTypes.string.isRequired,
};

export default TopbarBottomDefaultNav;
